import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
import { wistia_popover_button } from '../../components/wistia';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const WarningBlock = makeShortcode("WarningBlock");
const MyImage = makeShortcode("MyImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <WarningBlock mdxType="WarningBlock">CodeStory is currently in private beta.<br />
This documentation is a work in progress!</WarningBlock>
    <p>{`CodeStory is designed to help you describe the inner workings of your software projects in a whole new way.`}</p>
    <h2 {...{
      "id": "live-code-views",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#live-code-views",
        "aria-label": "live code views permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Live Code Views`}</h2>
    <p>{`Its most remarkable feature is the ability to incorporate live, editable views of your code base right into your notes.`}</p>
    <p>{`CodeStory also works together with git to connect your documentation notes to your code, and vice versa.`}</p>
    <p>{`Not only are the code views inside your notes always up-to-date, but they also are if you rename or move your files, or even refactor your code!`}</p>
    <MyImage src="Codeview.png" alt="CodeViews" mdxType="MyImage">
  <div>{wistia_popover_button('hrzq82losf', 'Watch Live Code Views in action')}</div>
    </MyImage>
    <h2 {...{
      "id": "sequence-diagrams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sequence-diagrams",
        "aria-label": "sequence diagrams permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Sequence Diagrams`}</h2>
    <p>{`Sequence diagrams offer an alternate way of describing a mechanism that involves somewhat complicated operations.`}</p>
    <MyImage src="Diagram1.png" alt="Diagrams" mdxType="MyImage">A bird-eye view of your code!</MyImage>
    <p>{`Sequence diagrams can point to notes that will show up when you hover over the relevant parts!`}</p>
    <MyImage src="Diagram2.png" alt="Diagrams" mdxType="MyImage">Hover the blue discs to reveal related notes / Live Code Views</MyImage>
    <p>{`CodeStory features a unique WYSIWYG editor which lets you build rich diagrams that reveal your code as you hover over the relevant parts.`}</p>
    <MyImage src="SequenceDiagramWYSIWYG.png" alt="SequenceDiagramWYSIWYG" mdxType="MyImage">
  The Sequence Diagram WYSIWYG editor.
    </MyImage>
    <h2 {...{
      "id": "notes-organization",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#notes-organization",
        "aria-label": "notes organization permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Notes organization`}</h2>
    <p>{`Notes are hierarchical: any note can be the parent of children notes.`}</p>
    <MyImage src="NotesHierarchy.png" alt="NotesHierarchy" mdxType="MyImage">
  A "dramatiq" note with 6 child notes.
    </MyImage>
    <h4 {...{
      "id": "table-of-contents",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#table-of-contents",
        "aria-label": "table of contents permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Table of Contents`}</h4>
    <p>{`The table of contents reflects that hierarchy.`}</p>
    <p>{`However, most of your notes probably don't need to show up there. Hence the option to disable “feature in TOC” when you create a new note (also modifiable later on).`}</p>
    <h4 {...{
      "id": "notes-browser",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#notes-browser",
        "aria-label": "notes browser permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Notes Browser`}</h4>
    <p>{`The Notes Browser displays all the notes in a flat, sortable, filterable list.`}</p>
    <MyImage src="NotesBrowser.png" alt="NotesBrowser" mdxType="MyImage">
  An unfeatured note
    </MyImage>
    <h2 {...{
      "id": "search",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#search",
        "aria-label": "search permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Search`}</h2>
    <p>{`One keyboard shortcut away, fast and works offline!`}</p>
    <MyImage src="Search.png" alt="Search" mdxType="MyImage">
    </MyImage>
    <h2 {...{
      "id": "ide-plug-ins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ide-plug-ins",
        "aria-label": "ide plug ins permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`IDE plug-ins`}</h2>
    <p>{`How often do you browse through some code and come across something you'd like to know the context of?`}</p>
    <p>{`Plug-ins for VSCode and Jetbrain are available (Atom and Sublime soon).`}</p>
    <MyImage src="VSCode.png" alt="VSCode" mdxType="MyImage">
  Live Code View tokens are highlighted. Relevant notes are one click away!
    </MyImage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      